var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('side-card', {
    attrs: {
      "fullscreen": _vm.isMobile,
      "height": _vm.sideCardHeight,
      "width": _vm.sideCardWidth
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      }
    },
    scopedSlots: _vm._u([{
      key: "menu",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t("sign.DocumentTemplateDetailTokenList.title")) + " ")];
      },
      proxy: true
    }])
  }, [_vm._t("addToken"), !_vm.isMobile && _vm.isSelectedItem ? _c('v-row', [_c('v-col', [_c('document-template-detail-token-list-keyboard-instructions', {
    staticClass: "mb-4"
  })], 1)], 1) : _vm._e(), _vm.isSelectedItem ? _c('v-row', [_c('v-col', [_c('document-template-detail-token-form', {
    attrs: {
      "page": _vm.page
    },
    on: {
      "save": function save($event) {
        return _vm.$emit('save');
      }
    }
  })], 1)], 1) : _vm._e(), !_vm.isSelectedItem ? _c('v-row', [_c('v-col', [_c('document-template-detail-token-list', {
    attrs: {
      "currentPageNumber": _vm.currentPageNumber,
      "isLoadingSave": _vm.isLoadingSave,
      "disabled": _vm.disabled,
      "page": _vm.page
    },
    on: {
      "setCurrentPage": _vm.setCurrentPage
    }
  })], 1)], 1) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }