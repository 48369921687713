import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { IThg } from "@/models/thg.entity";
import thgService from "@/services/thg/services/thgService";
import { ThgPageViewModelGen, ThgThgControllerFindAllPaginatedParamsGen } from "@/services/thg/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { ImpactTypeEnum } from "@/views/thg/enum/impact-type.enum";
import { Module, getModule } from "vuex-module-decorators";
import { ThgStatusEnum } from "../enum/thg/thg-status.enum";
import { PaginatedBaseStore } from "../paginated-base.store";
import { ThgDataAccessLayer } from "./access-layers/thg.access-layer";
import { PaginationFilterListElement } from "./base-pagination.store";
import { PageFilterTypes } from "@/lib/utility/data/page-filter-types.enum";

export class ThgPartnerPageDataProvider extends AbstractPageDataProvider<
  IThg,
  ThgThgControllerFindAllPaginatedParamsGen
> {
  itemsPerPage = 25;

  async getPage(query: ThgThgControllerFindAllPaginatedParamsGen): Promise<IPageViewModel<IThg>> {
    const res = (await thgService.getAllThgsPaginated(query)) as ThgPageViewModelGen & {
      data?: IThg[] | undefined;
    };

    const meta = res.meta;
    const data = res.data ?? [];

    return { meta, data };
  }
}
/**
 * Store for admins
 */
@Module({
  dynamic: true,
  namespaced: true,
  name: "thgPagination",
  store
})
export class ThePaginationStore extends PaginatedBaseStore<IThg, ThgThgControllerFindAllPaginatedParamsGen> {
  _data = ThgDataAccessLayer;
  _pageProvider = new ThgPartnerPageDataProvider();
  _pager = new PageDataHandler(this._data, this._pageProvider);

  /**
   * Filters, ordered by most used
   */
  filterOptions: PaginationFilterListElement[] = [
    { key: "registration.identificationnumber", type: PageFilterTypes.STRING },
    { key: "_id", type: PageFilterTypes.OBJECT_ID },
    { key: "userId", type: PageFilterTypes.OBJECT_ID },
    { key: "numberplate", type: PageFilterTypes.STRING },
    { key: "partnerId", type: PageFilterTypes.OBJECT_ID },
    { key: "status", type: PageFilterTypes.ENUM, config: { items: Object.values(ThgStatusEnum) } },
    { key: "year", type: PageFilterTypes.NUMBER },
    { key: "vehicleId", type: PageFilterTypes.OBJECT_ID },
    {
      key: "countryCode",
      type: PageFilterTypes.ENUM,
      config: { items: Object.values(CountryCodeEnum) }
    },
    { key: "timestamp.created", type: PageFilterTypes.DATE },
    {
      key: "impactType",
      type: PageFilterTypes.ENUM,
      config: { items: Object.values(ImpactTypeEnum) }
    },
    { key: "impactFactor", type: PageFilterTypes.NUMBER },
    { key: "code", type: PageFilterTypes.STRING },
    { key: "campaign", type: PageFilterTypes.STRING },
    { key: "isMultiyear", type: PageFilterTypes.BOOLEAN },
    { key: "isBillingInformationComplete", type: PageFilterTypes.BOOLEAN },
    { key: "registration.firstname", type: PageFilterTypes.STRING },
    { key: "registration.name", type: PageFilterTypes.STRING },
    { key: "registration.street", type: PageFilterTypes.STRING },
    { key: "registration.zipCode", type: PageFilterTypes.STRING },
    { key: "registration.city", type: PageFilterTypes.STRING },
    { key: "registration.huYear", type: PageFilterTypes.STRING },
    { key: "registration.huMonth", type: PageFilterTypes.STRING },
    { key: "registration.manufacturerNameCode", type: PageFilterTypes.STRING },
    { key: "registration.manufacturerTypeCode", type: PageFilterTypes.STRING },
    { key: "registration.driveTyp", type: PageFilterTypes.STRING },
    { key: "registration.numberplate", type: PageFilterTypes.STRING },
    { key: "registration.firstregistrationDay", type: PageFilterTypes.STRING },
    { key: "registration.firstregistrationMonth", type: PageFilterTypes.STRING },
    { key: "registration.firstregistrationYear", type: PageFilterTypes.STRING },
    { key: "registration.manufacturerName", type: PageFilterTypes.STRING },
    { key: "registration.manufacturerType", type: PageFilterTypes.STRING },
    { key: "registration.vehicleClass", type: PageFilterTypes.STRING }
  ].map(f => new PaginationFilterListElement(f));
}

export const ThgPaginationModule = getModule(ThePaginationStore);
